import { type ReplicationPullHandlerResult, type RxReplicationWriteToMasterRow, lastOfArray } from "rxdb";
import { replicateRxCollection } from "rxdb/plugins/replication";

import { supabaseClient } from "../../supabase/supabase";
import { rowToCheckpoint, rowToPostgres, rowToRxDoc } from "../../utilities/rxdbHelpers";
import type { ActivityCollection, ActivityDocType } from "../schemas/activity.types";
import type { QuestionDocType } from "../schemas/question.types";
import type { SupabaseReplicationCheckpoint } from "./";

const initializeActivitiesReplication = async (collection: ActivityCollection) => {
  return replicateRxCollection({
    collection,
    replicationIdentifier: "activities-replication",
    autoStart: true,
    live: true,
    pull: {
      async handler(
        lastCheckpoint: SupabaseReplicationCheckpoint | undefined,
        batchSize: number,
      ): Promise<ReplicationPullHandlerResult<ActivityDocType, SupabaseReplicationCheckpoint>> {
        const { data, error } = await supabaseClient.rpc("pull_activities", {
          batch_size: batchSize ? batchSize : null,
          last_id: lastCheckpoint?.id ?? null,
          last_pulled_at: lastCheckpoint?.modified ?? null,
        });

        if (error) throw error;

        if (data.length === 0) {
          return {
            checkpoint: lastCheckpoint ?? null,
            documents: [],
          };
        }

        return {
          checkpoint: rowToCheckpoint(lastOfArray(data) || {}),
          documents: data.map((row: any) => rowToRxDoc<QuestionDocType>(row)),
        };
      },
    },
    push: {
      async handler(rows: RxReplicationWriteToMasterRow<ActivityDocType>[]) {
        const activities = rows.map((row) => rowToPostgres(row.newDocumentState));

        const { error } = await supabaseClient.rpc("push_activities", {
          activities,
        });

        if (error) throw error;

        return [];
      },
      initialCheckpoint: "",
    },
  });
};

export { initializeActivitiesReplication };
