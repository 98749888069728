import log from "loglevel";

switch (import.meta.env.MODE) {
  case "development":
    log.setLevel("trace");
    break;
  case "production":
    log.setLevel("error");
    break;
  default:
    log.setLevel("warn");
    break;
}

const setLogLevel = (level: log.LogLevelDesc) => {
  if (
    [
      "trace",
      "debug",
      "info",
      "warn",
      "error",
      "silent",
      0,
      1,
      2,
      3,
      4,
      5,
    ].includes(level)
  ) {
    log.setLevel(level);
  } else {
    log.setLevel("silent");
  }
};

const getLogLevel = () => log.getLevel();

export { log, setLogLevel, getLogLevel };
