import {
  Button,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Drawer as HeroDrawer,
  Listbox,
  ListboxItem,
  ListboxSection,
  ScrollShadow,
} from "@heroui/react";
import type { FC } from "react";

import { CheckIcon, HomeIcon, LogOutIcon, SettingsIcon, TrueFalseIcon, XMarkIcon } from "../../components/icons";
import EliteHqIcon from "../../components/icons/elite-hq";
import { useConfiguration } from "../../hooks/data/useConfiguration";

type DrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  position?: "left" | "right";
};

const Drawer: FC<DrawerProps> = ({ isOpen, onClose, position = "right" }) => {
  const { config } = useConfiguration();

  return (
    <HeroDrawer backdrop="blur" isOpen={isOpen} onClose={onClose} placement={position} size="xs" hideCloseButton>
      <DrawerContent>
        {() => (
          <>
            <DrawerHeader className="flex align-center items-center justify-between gap-3">
              <EliteHqIcon height={24} width={48} />
              <Button isIconOnly variant="light" radius="full" onPress={onClose}>
                <XMarkIcon />
              </Button>
            </DrawerHeader>
            <DrawerBody className="px-4">
              <ScrollShadow className="h-full">
                <Listbox aria-label="Learning Feature" variant="flat">
                  <ListboxItem href="/" startContent={<HomeIcon />} onPress={onClose}>
                    Home
                  </ListboxItem>
                  <ListboxSection title="Learning Feature">
                    {config?.quiz?.series?.enabled || config?.quiz?.procedures?.enabled ? (
                      <ListboxItem href="/quizzes" startContent={<CheckIcon />} onPress={onClose}>
                        Quizzes
                      </ListboxItem>
                    ) : null}
                    {config?.trueFalse?.series?.enabled || config?.trueFalse?.procedures?.enabled ? (
                      <ListboxItem href="/true-false" startContent={<TrueFalseIcon />} onPress={onClose}>
                        True & False
                      </ListboxItem>
                    ) : null}
                    {config?.exam?.enabled ? (
                      <ListboxItem href="/exams" startContent={<CheckIcon />} onPress={onClose}>
                        Exams
                      </ListboxItem>
                    ) : null}
                  </ListboxSection>
                </Listbox>
              </ScrollShadow>
            </DrawerBody>
            <DrawerFooter className="flex flex-col gap-4">
              <Listbox aria-label="bottom navigation" variant="flat">
                <ListboxItem href="/settings" startContent={<SettingsIcon />} onPress={onClose}>
                  Settings
                </ListboxItem>
                <ListboxItem href="/logout" startContent={<LogOutIcon />} onPress={onClose}>
                  Log Out
                </ListboxItem>
              </Listbox>
            </DrawerFooter>
          </>
        )}
      </DrawerContent>
    </HeroDrawer>
  );
};

export { Drawer };
