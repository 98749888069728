import * as Sentry from "@sentry/react";
import { z } from "zod";

import { router } from "../providers/route.provider";
import { log } from "./logger";

const initializeSentry = () => {
  if (import.meta.env.PROD) {
    Sentry.init({
      dsn: "https://f9191c1b1a0716cf0428e9464cae4e20@o290873.ingest.us.sentry.io/4508738734981120",
      integrations: [
        Sentry.tanstackRouterBrowserTracingIntegration(router),
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      release: `elite-hq@${import.meta.env.VITE_APP_VERSION}`,
      tracesSampleRate: 0,
      tracePropagationTargets: [/^https:\/\/hq\.elitestrategictraining\.com/],
      replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
};

type SetUserInput = { id?: string; email?: string; username?: string };

const setSentryUser = ({ id, email, username }: SetUserInput) => {
  Sentry.setUser({
    ...(id && { id }),
    ...(email && { email }),
    ...(username && { username }),
  });
};

const sentryTagSchema = z.object({
  key: z
    .string()
    .max(32)
    .regex(/^[a-zA-Z0-9_.:,-]+$/),
  value: z
    .string()
    .max(200)
    .regex(/^[^\n]*$/),
});

const setSentryTag = (key: string, value: string) => {
  const validation = sentryTagSchema.safeParse({ key, value });

  if (!validation.success) {
    log.debug("Invalid Sentry tag:", validation.error);
    return;
  }

  Sentry.setTags({ [key]: value });
};

export { initializeSentry, setSentryUser, setSentryTag };
