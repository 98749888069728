import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { useEffect } from "react";

import { analytics } from "../services/firebase";

const useAnalytics = () => {
  useEffect(() => {
    // Initialize analytics here if needed
    setUserProperties(analytics, { internal: import.meta.env.DEV });
  }, []);

  const trackEvent = (eventName: string, eventParams?: object) => {
    logEvent(analytics, eventName, eventParams);
  };

  const setAnalyticsUserId = (userId: string) => {
    setUserId(analytics, userId);
  };

  const setUserProps = (propertyName: string, propertyValue: string) => {
    setUserProperties(analytics, { [propertyName]: propertyValue });
  };

  const trackScreenView = (screenName: string, screenClass?: string) =>
    logEvent(analytics, "screen_view", {
      firebase_screen: screenName,
      firebase_screen_class: screenClass,
    });

  return {
    trackEvent,
    setAnalyticsUserId,
    setUserProps,
    trackScreenView,
  };
};

export { useAnalytics };
