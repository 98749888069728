import type { RxJsonSchema } from "rxdb";
import { toTypedRxJsonSchema } from "rxdb";
import type { Tables } from "../../types/supabase";

// Extract the Supabase row type for the 'questions' table
type SupabaseQuestionRowType = Tables<"questions">;

// Create a new type by omitting '_deleted' and '_modified' from the Supabase row type
type QuestionRowType = Omit<SupabaseQuestionRowType, "_deleted" | "_modified" | "_created" | "ro">;

// Create a schema literal object based on the Supabase row type
export const questionSchemaLiteral = {
  title: "question",
  version: 0,
  primaryKey: "id",
  type: "object",
  properties: {
    id: { type: "string", maxLength: 20 },
    answer: { type: "array", items: { type: "string" } },
    answers: {
      type: "array",
      items: {
        type: "object",
        properties: {
          id: { type: "string" },
          text: { type: "string" },
          feedback: { type: "string" },
        },
      },
    },
    feedback: { type: "string" },
    procedures: { type: "array", items: { type: "string" } },
    rank: { type: "string", maxLength: 20 },
    series: { type: "array", items: { type: "string" } },
    tags: { type: "array", items: { type: "string" } },
    ro: { type: "string" },
    text: { type: "string", nullable: true },
    type: { type: "string", maxLength: 20 },
  },
  required: ["id", "answer", "answers", "feedback", "procedures", "rank", "series", "tags", "type", "text"],
  encrypted: ["text", "answers", "answer", "feedback"],
  indexes: ["type", "rank", ["type", "rank"]],
} as const;

// Convert the schema literal to an RxDB schema
const questionSchema: RxJsonSchema<QuestionRowType> = toTypedRxJsonSchema(questionSchemaLiteral);

export { questionSchema };
