import { use } from "react";

import type { DatabaseCollectionName, DatabaseCollections } from "../../db";
import { DataContext } from "../../providers/data.provider";

const useCollection = <T extends DatabaseCollectionName>(name: T) => {
  const { collections } = use(DataContext);

  if (!collections?.[name]) {
    throw new Error(`Collection ${name} not initialized`);
  }
  return collections[name] as DatabaseCollections[T];
};

export { useCollection };
