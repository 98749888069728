import { format } from "date-fns";
import { cloneDeep, includes, uniq } from "lodash";

import { useEffect, useState } from "react";
import { Theme } from "../../enum";
import { useDataState } from "../../providers/data.provider";

type ActiveDays = string[];

type SeenIds = Record<string, string[]>;

type Settings = {
  allowSecondAttempt: boolean;
  showAllFeedbackOnLastAttempt: boolean;
  showImmediateFeedback: boolean;
  showQuestionDetails: boolean;
  theme: Theme;
};

type UserState = {
  activeDays: ActiveDays;
  seenIds: SeenIds;
  settings: Settings;
};

const defaultState: UserState = {
  activeDays: [],
  seenIds: {},
  settings: {
    allowSecondAttempt: true,
    showAllFeedbackOnLastAttempt: true,
    showImmediateFeedback: true,
    showQuestionDetails: false,
    theme: Theme.Light,
  },
};

const useUser = () => {
  const user = useDataState("user");
  const [state, setState] = useState<UserState>(defaultState);

  if (!user) {
    throw new Error("User state not initialized");
  }

  useEffect(() => {
    const loadedState = cloneDeep(user.get());
    if (!loadedState.settings) {
      loadedState.settings = defaultState.settings;
    }
    if (!loadedState.activeDays) {
      loadedState.activeDays = [];
    }
    if (!loadedState.seenIds) {
      loadedState.seenIds = {};
    }
    setState(loadedState);

    const subscription = user.$.subscribe((data: UserState) => {
      if (!data.settings) {
        data.settings = defaultState.settings;
      }
      if (!data.activeDays) {
        data.activeDays = [];
      }
      if (!data.seenIds) {
        data.seenIds = {};
      }
      setState(data);
    });

    return () => subscription.unsubscribe();
  }, []);

  const addSeenIds = (seen: SeenIds) => {
    user
      .set("seenIds", (prevValue: SeenIds) => {
        const merged = { ...prevValue };
        Object.entries(seen).forEach(([series, ids]) => {
          merged[series] = uniq([...(merged[series] ?? []), ...ids]);
        });
        return merged;
      })
      .then(() => {
        setState(user.get());
      });
  };

  const getSeenIds = (series?: string) => (series ? (user.get().seenIds?.[series] ?? []) : (user.get().seenIds ?? []));

  const logActiveDay = () => {
    const date = format(new Date(), "yyyy-MM-dd");
    if (includes(user.get().activeDays, date)) return;
    user
      .set("activeDays", (prevValue: ActiveDays) => {
        return uniq([...(prevValue ?? []), date]);
      })
      .then(() => {
        setState(user.get());
      });
  };

  const resetSeenIds = (series: string) => {
    user
      .set("seenIds", (prevValue: SeenIds) => {
        return {
          ...prevValue,
          [series]: [],
        };
      })
      .then(() => {
        setState(user.get());
      });
  };

  const updateSetting = (field: keyof Settings, value: Settings[keyof Settings]) => {
    user
      .set("settings", (prevValue: Settings) => {
        return {
          ...prevValue,
          [field]: value,
        };
      })
      .then(() => {
        setState(user.get());
      });
  };

  return {
    ...state,
    addSeenIds,
    getSeenIds,
    logActiveDay,
    resetSeenIds,
    updateSetting,
  };
};

export { useUser };
