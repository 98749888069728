import { Button, cn } from "@heroui/react";
import { useMatches, useNavigate } from "@tanstack/react-router";
import { type FC, useMemo } from "react";

const BottomTabs: FC = () => {
  const matches = useMatches();
  const navigate = useNavigate();
  const tabNav = useMemo(() => matches[2]?.staticData?.navigation ?? undefined, [matches]);

  const buttonClassname = cn("h-full flex flex-col gap-0 p-2 w-15 h-15");

  return tabNav ? (
    <div className=" w-full h-16 py-2 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
      <div className="h-full max-w-sm flex flex-row gap-2 mx-auto">
        {tabNav.map(({ label, href, icon }) => {
          return (
            <Button className={buttonClassname} key={label} onPress={() => navigate({ href })}>
              {icon && icon}
              <span className="text-sm">{label}</span>
            </Button>
          );
        })}
      </div>
    </div>
  ) : null;
};

export { BottomTabs };
