const isAppleMobile = (): boolean => {
  return !!(
    (["iPhone", "iPad", "iPod"].includes(navigator.userAgent) ||
      (/iPad|iPhone|iPod/.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2)) &&
    "serviceWorker" in navigator
  );
};

const isAppleDesktop = () => {
  // check if it's a mac
  const userAgent = navigator.userAgent.toLowerCase();
  if (navigator.maxTouchPoints || !userAgent.match(/macintosh/)) return false;

  // check safari version >= 17
  const version = /version\/(\d{2})\./.exec(userAgent);
  if (!version || !version[1] || !(Number.parseInt(version[1]) >= 17)) return false;

  // hacky way to detect Sonoma
  const audioCheck = !!document.createElement("audio").canPlayType('audio/wav; codecs="1"');
  const webGLCheck = !!new OffscreenCanvas(1, 1).getContext("webgl");

  return audioCheck && webGLCheck;
};

const isStandalone = () =>
  window.matchMedia("(display-mode: standalone)").matches ||
  ("standalone" in navigator && (navigator as any).standalone === true);

export { isAppleMobile, isAppleDesktop, isStandalone };
