import { Button, Navbar as HeroNavbar, NavbarBrand, NavbarContent, NavbarItem } from "@heroui/react";
import { useMatches } from "@tanstack/react-router";
import { first } from "lodash";
import { type FC, useEffect, useState } from "react";

import { MenuIcon } from "../../components/icons";
import { EliteHqIcon } from "../../components/icons/index";

interface NavbarProps {
  sidebarIsOpen: boolean;
  sidebarOnOpen: () => void;
  sidebarOnClose: () => void;
}

const Navbar: FC<NavbarProps> = ({ sidebarOnOpen, sidebarIsOpen, sidebarOnClose }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [title, setTitle] = useState("");
  const matches = useMatches();

  useEffect(() => {
    const titlePromises = [...matches]
      .reverse()
      .map((match) => {
        return "getTitle" in match.context ? match?.context?.getTitle?.() : undefined;
      })
      .filter(Boolean);

    void Promise.all(titlePromises).then((titles) => {
      setTitle(first(titles) ?? "");
    });
  }, [matches]);

  return (
    <HeroNavbar
      isBordered
      position="static"
      maxWidth="lg"
      height="55px"
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
    >
      <NavbarContent justify="start">
        <NavbarBrand>
          <EliteHqIcon height={48} width={48} />
        </NavbarBrand>
      </NavbarContent>

      {title && <NavbarContent justify="center">{title}</NavbarContent>}

      <NavbarContent className="flex flex-row gap-3" justify="end">
        {/*<NavbarItem>*/}
        {/*  <Badge content="5" color="danger">*/}
        {/*    <Button isIconOnly variant="light">*/}
        {/*      Bell*/}
        {/*    </Button>*/}
        {/*  </Badge>*/}
        {/*</NavbarItem>*/}
        <NavbarItem>
          <Button isIconOnly variant="light" onPress={sidebarIsOpen ? sidebarOnClose : sidebarOnOpen}>
            <MenuIcon />
          </Button>
        </NavbarItem>
      </NavbarContent>
    </HeroNavbar>
  );
};

export { Navbar };
