import type { RxJsonSchema } from "rxdb";
import { toTypedRxJsonSchema } from "rxdb";
import type { Tables } from "../../types/supabase";

// Extract the Supabase row type for the 'questions' table
type SupabaseUserRowType = Tables<"configuration">;

// Create a new type by omitting '_deleted' and '_modified' from the Supabase row type
type ConfigurationRowType = Omit<
  SupabaseUserRowType,
  "_deleted" | "_modified" | "_created"
>;

// Create a schema literal object based on the Supabase row type
export const configurationSchemaLiteral = {
  title: "configuration",
  version: 0,
  primaryKey: "id",
  type: "object",
  properties: {
    id: { type: "string", maxLength: 100 },
    value: {
      type: "object",
      patternProperties: {
        "^labels": {
          type: "object",
          patternProperties: {
            "^.*": { type: "string" },
          },
        },
        "^(sergeant|lieutenant|captain)": {
          type: "object",
          properties: {
            exam: {
              type: "object",
              properties: {
                items: {
                  type: "array",
                  items: {
                    type: "string",
                  },
                },
                enabled: {
                  type: "boolean",
                },
              },
            },
            quiz: {
              type: "object",
              properties: {
                series: {
                  type: "object",
                  properties: {
                    items: {
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                    enabled: {
                      type: "boolean",
                    },
                  },
                },
                enabled: {
                  type: "boolean",
                },
                procedures: {
                  type: "object",
                  properties: {
                    items: {
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                    enabled: {
                      type: "boolean",
                    },
                  },
                },
              },
            },
            trueFalse: {
              type: "object",
              properties: {
                series: {
                  type: "object",
                  properties: {
                    items: {
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                    enabled: {
                      type: "boolean",
                    },
                  },
                },
                enabled: {
                  type: "boolean",
                },
                procedures: {
                  type: "object",
                  properties: {
                    items: {
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                    enabled: {
                      type: "boolean",
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  required: ["id", "value"],
} as const;

// Convert the schema literal to an RxDB schema
const configurationSchema: RxJsonSchema<ConfigurationRowType> =
  toTypedRxJsonSchema(configurationSchemaLiteral);

export { configurationSchema };
