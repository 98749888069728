import { Progress } from "@heroui/react";
import { useEffect, useState } from "react";

import { useReplication } from "../../providers/replication.provider";

const QuestionStatus = () => {
  const [active, setActive] = useState(true);
  const { questions } = useReplication();

  useEffect(() => {
    const activeSub = questions?.active$.subscribe((status) => {
      setActive(status);
    });
    return () => activeSub?.unsubscribe();
  }, [questions]);

  return active ? (
    <div className="w-full max-w-screen-lg mx-auto bg-white p-2">
      <Progress
        isIndeterminate
        label="questions loading, please wait..."
        aria-label="Loading..."
        color="secondary"
        size="sm"
      />
    </div>
  ) : (
    <></>
  );
};

export { QuestionStatus };
