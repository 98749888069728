import { create } from "zustand";
import { isAppleDesktop, isAppleMobile, isStandalone } from "../utilities/device";

type PwaStore = {
  deferredPrompt: BeforeInstallPromptEvent | null;
  isStandalone: boolean;
  isAppleMobilePlatform: boolean;
  isAppleDesktopPlatform: boolean;
  canInstall: boolean;
  isOffline: boolean;
};

type PwaActions = {
  setOffline: (offline?: boolean) => void;
  promptInstall: () => void;
  capturePrompt: (event: BeforeInstallPromptEvent) => void;
};

const usePwaStore = create<PwaStore & PwaActions>((set, get) => ({
  canInstall: false,
  deferredPrompt: null,
  isAppleDesktopPlatform: isAppleDesktop(),
  isAppleMobilePlatform: isAppleMobile(),
  isOffline: false,
  isStandalone: isStandalone(),
  capturePrompt: (event: BeforeInstallPromptEvent) => set({ canInstall: true, deferredPrompt: event }),
  promptInstall: async () => {
    const { deferredPrompt } = get();
    if (!deferredPrompt) return;
    await deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        set({
          canInstall: false,
          deferredPrompt: null,
        });
        console.log("User accepted the A2HS prompt");
      } else {
        set({
          deferredPrompt: null,
        });
        console.log("User dismissed the A2HS prompt");
      }
    });
  },
  setOffline: (offline?: boolean) =>
    set((state) => ({ isOffline: offline !== undefined ? offline : !state.isOffline })),
}));

export { usePwaStore };
