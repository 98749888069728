import { groupBy, includes, isEqual, sample, uniq } from "lodash";

import { getDatabase } from "../index";
import { activitySchema } from "../schemas/activity";
import type {
  ActivityAnswered,
  ActivityCollection,
  ActivityCollectionMethods,
  ActivityDocument,
  ActivityQuestionAnswer,
  ActivityResults,
  ActivitySummary,
  ActivitySummaryBreakdown,
} from "../schemas/activity.types";
import type { QuestionDocument } from "../schemas/question.types";

const getCount: ActivityCollectionMethods["getCount"] = async function (this: ActivityCollection): Promise<number> {
  const docs = await this.find().exec();
  return docs.length;
};

const getQuestion = async function (this: ActivityDocument): Promise<QuestionDocument | null> {
  const latest = this.getLatest();
  const questions = latest.question_ids;
  const completed = latest.completed_ids;
  const remaining = questions.filter((id: string) => !includes(completed, id));
  const id = sample(remaining);
  if (id) {
    const db = await getDatabase();
    return db?.collections?.questions?.findOne(String(id)).exec() ?? null;
  }
  return null;
};

type AnswerQuestionInput = {
  questionId: string;
  submitted: string[];
  correct: boolean;
  series: string[];
  procedures: string[];
};

const answerQuestion = async function (
  this: ActivityDocument,
  { questionId, submitted, correct, series, procedures }: AnswerQuestionInput,
): Promise<void> {
  this.incrementalModify((doc) => {
    if (includes(doc.completed_ids, questionId)) {
      return doc;
    }
    doc.completed_ids = uniq([...doc.completed_ids, questionId]);
    doc.answered = [
      ...doc.answered,
      {
        questionId,
        submitted,
        correct,
        series,
        procedures,
      },
    ];
    if (isEqual(doc.completed_ids.sort(), doc.question_ids.sort())) {
      doc.completed = true;
    }
    return doc;
  });
  return;
};

const generateSummary = async (activity: ActivityDocument): Promise<ActivitySummary> => {
  const completed = await activity.getLatest().get("completed");
  const answered = await activity.getLatest().get("answered");
  const questionIds = await activity.getLatest().get("question_ids");

  const correct = answered.filter((a: any) => a.correct).length;
  const incorrect = answered.filter((a: any) => !a.correct).length;
  const totalAnswered = answered.length;
  const totalQuestions = questionIds.length;
  const score = Math.round((correct / totalAnswered) * 100);

  const seriesAnswers = groupBy(answered, (answer) => answer.series);
  const proceduresAnswers = groupBy(answered, (answer) => answer.procedures);
  const breakdownSeries: ActivitySummaryBreakdown = {};
  const breakdownProcedures: ActivitySummaryBreakdown = {};

  for (const [series, answerArray] of Object.entries(seriesAnswers)) {
    const correctCount = answerArray.filter((answer) => answer.correct).length;
    const seriesScore = Math.round((correctCount / answerArray.length) * 100);
    breakdownSeries[series] = {
      score: seriesScore,
      total: answerArray.length,
      correct: correctCount,
    };
  }

  for (const [procedure, answerArray] of Object.entries(proceduresAnswers)) {
    const correctCount = answerArray.filter((answer) => answer.correct).length;
    const procedureScore = Math.round((correctCount / answerArray.length) * 100);
    breakdownProcedures[procedure] = {
      score: procedureScore,
      total: answerArray.length,
      correct: correctCount,
    };
  }

  return {
    completed,
    correct,
    incorrect,
    score,
    totalAnswered,
    totalQuestions,
    breakdown: {
      series: breakdownSeries,
      procedures: breakdownProcedures,
    },
  };
};

const summarize = async function (this: ActivityDocument): Promise<ActivitySummary> {
  return generateSummary(this);
};

const results = async function (this: ActivityDocument): Promise<ActivityResults> {
  const latestDoc = this.getLatest();
  const completed = await latestDoc.get("completed");

  if (!completed) {
    return { error: "Activity not completed" };
  }

  const db = await getDatabase();
  const questionsIds = await latestDoc.get("question_ids");

  const answered = latestDoc.get("answered").reduce((acc: ActivityAnswered, curr: ActivityQuestionAnswer) => {
    acc[curr.questionId] = curr;
    return acc;
  }, {});

  const questions = await db?.collections?.questions
    ?.findByIds(questionsIds)
    .exec()

    .then((docs) => Array.from(docs, ([, value]) => value.toJSON()));
  const summary = await generateSummary(latestDoc);

  return {
    summary,
    questions,
    answered,
  };
};

export const activityCollection = {
  schema: activitySchema,
  statics: { getCount },
  methods: { answerQuestion, getQuestion, summarize, results },
  migrationStrategy: {},
};
