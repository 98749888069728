import { Alert, Button } from "@heroui/react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";

import { ServiceWorker } from "./components/global/ServiceWorker";
import { SingleTabEnforcer } from "./components/global/SingleTabEnforcer";
import { AuthProvider } from "./providers/auth.provider";
import { DataProvider } from "./providers/data.provider";
import { RouteProvider } from "./providers/route.provider";
import { ThemeProvider } from "./providers/theme.provider";
import { usePwaStore } from "./stores/pwa";
import { initializeSentry } from "./utilities/sentry";

import "@khmyznikov/pwa-install";
import "./styles/globals.css";

initializeSentry();

const rootElement = document.getElementById("root") as HTMLElement;

window.addEventListener("beforeinstallprompt", (e) => {
  usePwaStore.getState().capturePrompt(e);
});

const ErrorFallback = () => (
  <div className="flex min-h-screen items-center justify-center">
    <Alert
      className="max-w-lg"
      title="Something went wrong"
      description={"The application encountered an error. Please reload the page."}
      color="danger"
    >
      <Button
        className="mt-4"
        size="sm"
        variant="solid"
        color="danger"
        onPress={() => {
          window.location.reload();
        }}
      >
        Reload
      </Button>
    </Alert>
  </div>
);

createRoot(rootElement).render(
  <StrictMode>
    <ThemeProvider>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <SingleTabEnforcer>
          <AuthProvider>
            <DataProvider>
              <RouteProvider />
            </DataProvider>
          </AuthProvider>
        </SingleTabEnforcer>
      </ErrorBoundary>
    </ThemeProvider>
    <ServiceWorker />
  </StrictMode>,
);
