import { type RxState, lastOfArray } from "rxdb";
import { replicateRxCollection } from "rxdb/plugins/replication";

import { supabaseClient } from "../../supabase/supabase";
import { rowToCheckpoint, rowToRxDoc, stateRowToPostgres } from "../../utilities/rxdbHelpers";
import type { SupabaseReplicationCheckpoint } from "./";

const initializeUserStateReplication = async (userState: RxState<any>) => {
  return replicateRxCollection<any, SupabaseReplicationCheckpoint>({
    collection: userState.collection,
    replicationIdentifier: "user-state-replication",
    autoStart: true,
    live: true,
    pull: {
      async handler(lastCheckpoint: SupabaseReplicationCheckpoint | undefined) {
        const { data, error } = await supabaseClient.rpc("pull_state", {
          last_id: lastCheckpoint?.id,
          last_pulled_at: lastCheckpoint?.modified,
        });

        if (error) throw error;

        if (data.length === 0) {
          return {
            checkpoint: lastCheckpoint ?? null,
            documents: [],
          };
        }

        return {
          checkpoint: rowToCheckpoint(lastOfArray(data) || {}),
          documents: data.map((row: any) => rowToRxDoc(row)),
        };
      },
    },
    push: {
      async handler(rows) {
        const stateRows = rows.map((row) => stateRowToPostgres(row.newDocumentState));

        const { error } = await supabaseClient.rpc("push_state", {
          rows: stateRows,
        });

        if (error) throw error;

        return [];
      },
      initialCheckpoint: "",
    },
  });
};

export { initializeUserStateReplication };
