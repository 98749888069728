import { Button, Spinner } from "@heroui/react";
import { useState } from "react";
import { useTimeout } from "usehooks-ts";

const Reload = () => {
  const handleReload = () => location.reload();
  return (
    <div className="flex flex-col justify-center items-center gap-4">
      <div className="text-center">
        <h1 className="text-2xl font-bold">Something went wrong</h1>
        <p>Please reload the page.</p>
      </div>
      <Button onPress={handleReload} color="primary" variant="bordered">
        Reload
      </Button>
    </div>
  );
};

const Loader = () => {
  const [showReload, setShowReload] = useState(false);

  useTimeout(() => {
    setShowReload(true);
  }, 10000);

  return (
    <div className="flex flex-row min-h-screen justify-center items-center">
      {showReload ? <Reload /> : <Spinner label="Loading..." color="primary" />}
    </div>
  );
};

export default Loader;
