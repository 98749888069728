import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

// Web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCcsylyVWYfhStO09i5DfrbareVhFOBKMw",
  projectId: "elite-hq-7bac9",
  messagingSenderId: "886810427733",
  appId: "1:886810427733:web:6e8b0bfd646fc4515bafdc",
  measurementId: "G-W9BGV16DWR",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);
const analytics = getAnalytics(app);

export { analytics };
