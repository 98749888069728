import { Spinner } from "@heroui/react";
import type { FC, PropsWithChildren } from "react";

import { SupabaseProvider } from "@est/supabase";
import { useAuth, useAuthStateChange } from "@est/supabase/hooks";

import { useAnalytics } from "../hooks";
import { supabaseClient } from "../supabase/supabase";
import { setSentryUser } from "../utilities/sentry";

const AuthChecker: FC<PropsWithChildren> = ({ children }) => {
  const { initialized } = useAuth();
  const { setAnalyticsUserId } = useAnalytics();

  useAuthStateChange((event, session) => {
    if (event && session?.user) {
      setSentryUser({ id: session.user.app_metadata.id, username: session.user.app_metadata.username });
      setAnalyticsUserId(session.user.app_metadata.id);
    } else {
      setSentryUser({});
    }
  }, supabaseClient);

  if (!initialized) {
    return <Spinner />;
  }

  return children;
};

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <SupabaseProvider client={supabaseClient}>
      <AuthChecker>{children}</AuthChecker>
    </SupabaseProvider>
  );
};

export { AuthProvider };
