import { HeroUIProvider } from "@heroui/react";
import type { FC, PropsWithChildren } from "react";

import { useRouterStore } from "../stores";

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const { navigate } = useRouterStore();

  return <HeroUIProvider navigate={navigate}>{children}</HeroUIProvider>;
};

export { ThemeProvider };
