import { Outlet, createRootRouteWithContext, useLocation, useNavigate } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { useEffect } from "react";

import { ToastProvider } from "@heroui/react";
import { useAnalytics } from "../hooks";
import type { RouteContext } from "../providers/route.provider";
import { useRouterStore } from "../stores";

const Root = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setNavigate } = useRouterStore();
  const { trackScreenView } = useAnalytics();

  useEffect(() => {
    setNavigate((route) => navigate({ to: route }));
  }, []);

  useEffect(() => {
    trackScreenView(pathname.replace(/\/activity\/[a-zA-Z]+/, "/activity/*"));
  }, [pathname]);

  return (
    <div className="h-dvh w-dvw content">
      <Outlet />
      <ToastProvider toastProps={{ timeout: 4000 }} />
      {import.meta.env.VITE_ROUTE_DEV && <TanStackRouterDevtools position="bottom-right" />}
    </div>
  );
};

export const Route = createRootRouteWithContext<RouteContext>()({
  component: Root,
});
