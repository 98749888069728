import type { RxJsonSchema } from "rxdb";
import { toTypedRxJsonSchema } from "rxdb";
import type { Tables } from "../../types/supabase";

// Extract the Supabase row type for the 'questions' table
type SupabaseActivityRowType = Tables<"activities">;

// Create a new type by omitting '_deleted' and '_modified' from the Supabase row type
type ActivityRowType = Omit<SupabaseActivityRowType, "_deleted" | "_modified" | "_created">;

// Create a schema literal object based on the Supabase row type
export const activitySchemaLiteral = {
  title: "activity",
  version: 0,
  primaryKey: "id",
  type: "object",
  properties: {
    id: { type: "string", maxLength: 100 },
    user_id: { type: "string" },
    type: { type: "string", maxLength: 100 },
    name: { type: "string" },
    description: { type: "string" },
    answered: {
      type: "array",
      items: {
        type: "object",
        properties: {
          questionId: { type: "string" },
          submitted: { type: "array", items: { type: "string" } },
          series: { type: "array", items: { type: "string" } },
          procedures: { type: "array", items: { type: "string" } },
          correct: { type: "boolean" },
        },
      },
    },
    question_ids: { type: "array", items: { type: "string" } },
    completed_ids: { type: "array", items: { type: "string" } },
    completed: { type: "boolean" },
  },
  required: ["id", "question_ids", "completed_ids", "completed", "name", "description", "answered", "type"],
  indexes: ["type"],
} as const;

export type ActivitySchema = RxJsonSchema<ActivityRowType>;

// Convert the schema literal to an RxDB schema
const activitySchema: ActivitySchema = toTypedRxJsonSchema(activitySchemaLiteral);

export { activitySchema };
