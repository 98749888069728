import { Outlet, createFileRoute } from "@tanstack/react-router";

const RouteComponent = () => {
  return <Outlet />;
};

export const Route = createFileRoute("/_app/settings")({
  beforeLoad: () => ({ getTitle: () => "Settings" }),
  // staticData: {
  //   navigation: [
  //     {
  //       label: "Activities",
  //       href: "/settings/activities",
  //       icon: <SettingsIcon />,
  //     },
  //     {
  //       label: "About",
  //       href: "/settings/about",
  //       icon: <SettingsIcon />,
  //     },
  //   ],
  // },
  component: RouteComponent,
});
