import type { Session, SupabaseClient, User } from "@supabase/supabase-js";
import { type FC, type PropsWithChildren, type ReactNode, createContext, useEffect, useState } from "react";
import { useAuthStateChange } from "../hooks";

export type SupabaseAuthState = {
  session: Session | null;
  user: User | null;
  claims: User["app_metadata"] | null;
  metadata: User["user_metadata"] | null;
  initialized: boolean;
};

type SupabaseContextState = {
  client?: SupabaseClient;
  auth?: SupabaseAuthState;
};

export const SupabaseContext = createContext<SupabaseContextState>({});

type SupabaseProviderProps = {
  client: SupabaseClient;
  loader?: ReactNode;
};

export const SupabaseProvider: FC<PropsWithChildren<SupabaseProviderProps>> = ({ client, children, loader }) => {
  if (!client) {
    throw new Error("SupabaseProvider requires a client");
  }
  const [auth, setAuth] = useState<SupabaseAuthState>({
    claims: null,
    metadata: null,
    session: null,
    user: null,
    initialized: false,
  });

  useEffect(() => {
    (async () => {
      const { data } = await client.auth.getSession();
      setAuth({
        claims: data.session?.user?.app_metadata ?? null,
        metadata: data.session?.user?.user_metadata ?? null,
        session: data.session,
        user: data.session?.user ?? null,
        initialized: true,
      });
    })();
  }, []);

  useAuthStateChange((event, session) => {
    if (event) {
      setAuth({
        claims: session?.user?.app_metadata ?? null,
        metadata: session?.user?.user_metadata ?? null,
        session,
        user: session?.user ?? null,
        initialized: true,
      });
    }
  }, client);

  if (loader && !auth.initialized) {
    return loader;
  }

  return <SupabaseContext.Provider value={{ client, auth }}>{children}</SupabaseContext.Provider>;
};
