import {
  type FC,
  type PropsWithChildren,
  createContext,
  use,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { RxState } from "rxdb";
import Loader from "../components/loader";
import { type Database, type DatabaseCollectionName, type DatabaseCollections, initializeDatabase } from "../db";

import { activityCollection, configurationCollection, questionCollection } from "../db/collections";
import { log } from "../utilities/logger";

type DataContextState = {
  db: Database | undefined;
  collections: DatabaseCollections | undefined;
  states:
    | {
        user?: RxState<any>;
        [name: string]: RxState<any>;
      }
    | undefined;
  actions:
    | {
        resetUserData: () => Promise<void>;
      }
    | undefined;
};

export const DataContext = createContext<DataContextState>({
  db: undefined,
  collections: undefined,
  states: undefined,
  actions: undefined,
});

const DataProvider: FC<PropsWithChildren> = ({ children }) => {
  const [db, setDb] = useState<Database>();
  const [collections, setCollections] = useState<DatabaseCollections>();
  const [states, setStates] = useState<Record<string, RxState<any>>>();

  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) return;
    mounted.current = true;

    initializeDatabase().then(async (database) => {
      setDb(database);

      const collections = await database
        .addCollections({
          activities: activityCollection,
          questions: questionCollection,
          configuration: configurationCollection,
        })
        .catch((error) => {
          log.error("Database collection error:", error);
          if (error.code === "DB3") {
            window.location.reload();
          }
        });

      setCollections(collections as DatabaseCollections);

      try {
        const states: Record<string, RxState<any>> = {
          user: await database.addState("user"),
          application: await database.addState("application"),
        };

        setStates(states);
      } catch (e) {
        log.error("Database states error:", e);
      }
    });
  }, []);

  const resetUserData = useCallback(async () => {
    await collections?.activities?.remove();
    await states?.user?.collection.remove();

    // const activities = await db?.addCollections({
    //   activities: activityCollection,
    // });
    //
    // const user = await db?.addState("user");
    //
    // setCollections((prevState) => {
    //   return {
    //     ...(prevState && { ...prevState }),
    //     activities,
    //   } as DatabaseCollections;
    // });

    // setStates((prevState) => {
    //   return {
    //     ...(prevState && { ...prevState }),
    //     user,
    //   };
    // });
  }, [db, collections, states]);

  const contextValue = useMemo(
    () => ({
      db,
      collections,
      states,
      actions: {
        resetUserData,
      },
    }),
    [db, collections, states, resetUserData],
  );

  if (!db || !collections || !states) {
    return <Loader />;
  }

  return <DataContext value={contextValue}>{children}</DataContext>;
};

const useDataCollection = <T extends DatabaseCollectionName>(name: T) => {
  const { collections } = use(DataContext);

  if (!collections?.[name]) {
    throw new Error(`Collection ${name} not initialized`);
  }
  return collections[name] as DatabaseCollections[T];
};

const useDataState = (name: string): RxState<any> | null => {
  const { states } = use(DataContext);

  if (!states?.[name]) {
    throw new Error(`State ${name} not initialized`);
  }

  return states?.[name];
};

const useDataActions = () => {
  const { actions } = use(DataContext);

  return actions;
};

export { DataProvider, useDataCollection, useDataState, useDataActions };
