import { questionSchema } from "../schemas/question";
import type { QuestionCollection, QuestionCollectionMethods, QuestionDocument } from "../schemas/question.types";

// collection methods (static)
const getCount: QuestionCollectionMethods["getCount"] = async function (this: QuestionCollection) {
  const docs = await this.find().exec();
  return docs.length;
};

// document methods (methods)
const isCorrectAnswer = function (this: QuestionDocument, answerIds: string[]): boolean {
  if (!this.answer || this.answer.length !== answerIds.length) {
    return false;
  }
  return this.answer?.every((answerId) => answerIds.includes(answerId)) ?? false;
};

export const questionCollection = {
  schema: questionSchema,
  statics: { getCount },
  methods: { isCorrectAnswer },
  migrationStrategy: {},
};
