import { Alert } from "@heroui/react";
import { type FC, type PropsWithChildren, useEffect, useState } from "react";

const SingleTabEnforcer: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const channel = new BroadcastChannel("elite-hq-tab-channel");

    const handleMessage = (event: MessageEvent<any>) => {
      if (event.data === "CLOSE_OTHER_TABS") {
        setIsOpen(false);
      }
    };

    channel.addEventListener("message", handleMessage);

    // Notify other tabs to close
    channel.postMessage("CLOSE_OTHER_TABS");

    return () => {
      channel.removeEventListener("message", handleMessage);
      channel.close();
    };
  }, []);

  return isOpen ? (
    children
  ) : (
    <div className="flex min-h-screen items-center justify-center">
      <Alert color="default" variant="flat" className={"max-w-md"}>
        This app can only be opened in one tab at a time.
      </Alert>
    </div>
  );
};

export { SingleTabEnforcer };
