import type { AuthChangeEvent, Session, SupabaseClient } from "@supabase/supabase-js";
import { useEffect } from "react";

export const useAuthStateChange = (
  callback: (event: AuthChangeEvent, session: Session | null) => void,
  client: SupabaseClient,
) => {
  useEffect(() => {
    const { data: authListener } = client.auth.onAuthStateChange(callback);
    return () => {
      authListener?.subscription?.unsubscribe();
    };
  }, [client]);
};
