import { Outlet, createFileRoute, redirect } from "@tanstack/react-router";

import { AdvancedLayout } from "../layout/advanced.layout";
import { ReplicationProvider } from "../providers/replication.provider";

const Component = () => {
  return (
    <ReplicationProvider>
      <AdvancedLayout>
        <Outlet />
      </AdvancedLayout>
    </ReplicationProvider>
  );
};

export const Route = createFileRoute("/_app")({
  component: Component,
  notFoundComponent: () => <h1>Not Found</h1>,
  beforeLoad: async ({ context }) => {
    if (!context.user) {
      throw redirect({
        to: "/login",
      });
    }
  },
});
