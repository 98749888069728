import { Cron } from "croner";
import { differenceInMinutes, format } from "date-fns";
import { type FC, type PropsWithChildren, createContext, use, useEffect, useMemo, useState } from "react";
import type { RxReplicationState } from "rxdb/plugins/replication";

import {
  initializeActivitiesReplication,
  initializeConfigurationReplication,
  initializeQuestionsReplication,
} from "../db/replication";
import { initializeUserStateReplication } from "../db/replication/user";
import { useApplication } from "../hooks/data/useApplication";
import { log } from "../utilities";
import { useDataCollection, useDataState } from "./data.provider";

type ReplicationContext = {
  cronJob?: Cron | undefined;
  user?: RxReplicationState<any, any> | undefined;
  questions?: RxReplicationState<any, any> | undefined;
  activities?: RxReplicationState<any, any> | undefined;
  configuration?: RxReplicationState<any, any> | undefined;
};

const Context = createContext<ReplicationContext>({});

const ReplicationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [activities, setActivities] = useState<RxReplicationState<any, any>>();
  const [configuration, setConfiguration] = useState<RxReplicationState<any, any>>();
  const [questions, setQuestions] = useState<RxReplicationState<any, any>>();
  const [user, setUser] = useState<RxReplicationState<any, any>>();

  const activitiesCollection = useDataCollection("activities");
  const configurationCollection = useDataCollection("configuration");
  const questionsCollection = useDataCollection("questions");
  const userState = useDataState("user");
  const { lastFrequent, lastInfrequent, updateTimestamp } = useApplication();

  useEffect(() => {
    if (activitiesCollection) {
      initializeActivitiesReplication(activitiesCollection).then(setActivities);
      return () => {
        void activities?.cancel();
      };
    }
  }, [activitiesCollection]);

  useEffect(() => {
    if (configurationCollection) {
      initializeConfigurationReplication(configurationCollection).then(setConfiguration);
      return () => {
        void configuration?.cancel();
      };
    }
  }, [configurationCollection]);

  useEffect(() => {
    if (questionsCollection) {
      initializeQuestionsReplication(questionsCollection).then(setQuestions);
      return () => {
        void questions?.cancel();
      };
    }
  }, [questionsCollection]);

  useEffect(() => {
    if (userState) {
      initializeUserStateReplication(userState).then(setUser);
      return () => {
        void user?.cancel();
      };
    }
  }, [userState]);

  useEffect(() => {
    const job = new Cron("*/1 * * * *", () => {
      const now = Date.now();
      const diffFrequent = differenceInMinutes(now, lastFrequent ?? 0);
      const diffInfrequent = differenceInMinutes(now, lastInfrequent ?? 0);

      log.debug(
        `Running tasks (${format(now, "MM/dd/yyyy HH:mm:ss")});`,
        `Frequent tasks last ran ${diffFrequent} minutes ago;`,
        `Infrequent tasks last ran ${diffInfrequent} minutes ago;`,
      );

      if (diffFrequent >= 1) {
        activities?.reSync();
        user?.reSync();
        updateTimestamp("lastFrequent", now);
      }

      if (diffInfrequent >= 20) {
        questions?.reSync();
        configuration?.reSync();
        updateTimestamp("lastInfrequent", now);
      }
    });

    return () => {
      job.stop();
    };
  }, [updateTimestamp, lastFrequent, lastInfrequent]);

  const contextValue = useMemo(
    () => ({
      user,
      questions,
      activities,
      configuration,
    }),
    [user, questions, activities, configuration],
  );

  return <Context value={contextValue}>{children}</Context>;
};

const useReplication = () => {
  const rep = use(Context);

  if (!rep) {
    throw new Error("useReplication must be used within a ReplicationProvider");
  }
  return rep;
};

export { ReplicationProvider, useReplication };
