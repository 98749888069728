import { createFileRoute, notFound } from "@tanstack/react-router";

export const Route = createFileRoute("/_app/activity/$activityId")({
  beforeLoad: () => ({
    getTitle: () => "Activity",
  }),
  loader: async ({ context: { activities }, params: { activityId } }) => {
    const activity = await activities?.findOne?.(activityId).exec();
    if (activity) {
      return activity;
    }
    throw notFound();
  },
});
