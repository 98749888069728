import { isArray, isBoolean, isObject } from "lodash";
import type { WithDeleted, WithDeletedAndAttachments } from "rxdb";

import type { SupabaseReplicationCheckpoint } from "../db/replication";
import type { ActivityDocType } from "../db/schemas/activity.types";
import type { QuestionDocType } from "../db/schemas/question.types";
import { DatabaseFields } from "../enum";

export const rowToRxDoc = <RxDocType>(row: any): WithDeleted<RxDocType> => {
  delete row[DatabaseFields.Modified];
  delete row[DatabaseFields.Created];
  row[DatabaseFields.Primary] = String(row[DatabaseFields.Primary]);
  return row as WithDeleted<RxDocType>;
};

export const postgresToRxState = <RxState>(row: any): WithDeleted<RxState> => {
  delete row[DatabaseFields.Modified];
  row.sId = row.sid;
  return row as WithDeleted<RxState>;
};

export const rowToCheckpoint = (
  row: Record<string, any>,
): SupabaseReplicationCheckpoint => ({
  modified: row[DatabaseFields.Modified],
  id: row[DatabaseFields.Primary],
});

export const rowToPostgres = (
  row: WithDeletedAndAttachments<ActivityDocType | QuestionDocType | any>,
) => {
  return Object.entries(row).reduce(
    (acc, [key, value]) => {
      acc[key] =
        isArray(value) || isObject(value) || isBoolean(value)
          ? JSON.stringify(value)
          : (value as string);
      return acc;
    },
    {} as Record<string, string>,
  );
};

export const stateRowToPostgres = (row: any) => {
  row.sid = row.sId;
  return Object.entries(row).reduce(
    (acc, [key, value]) => {
      acc[key] =
        isArray(value) || isObject(value) || isBoolean(value)
          ? JSON.stringify(value)
          : (value as string);
      return acc;
    },
    {} as Record<string, string>,
  );
};
