import { Link } from "@heroui/react";
import type { FC } from "react";

const ErrorMessage: FC = () => {
  return (
    <div>
      <p>Error found!</p>
      <Link href="/">Go home</Link>
    </div>
  );
};

export { ErrorMessage };
