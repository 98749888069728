import { create } from "zustand";

import type { ActivityCollection } from "../db/schemas";
import { log } from "../utilities";

type Navigate = (route: string) => void;

interface RouterState {
  activitiesCollection: ActivityCollection | null;
  navigate: Navigate;
  setActivitiesCollection: (activities: ActivityCollection) => void;
  setNavigate: (navigate: Navigate) => void;
}

const useRouterStore = create<RouterState>((set) => ({
  activitiesCollection: null,
  navigate: (route) => {
    log.debug(`navigate to ${route}`);
  },
  setNavigate: (navigate) =>
    set({
      navigate,
    }),
  setActivitiesCollection: (activitiesCollection) => set({ activitiesCollection }),
}));

export { useRouterStore };
