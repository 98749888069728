import { type RxDatabase, addRxPlugin, createRxDatabase } from "rxdb";
import { RxDBCleanupPlugin } from "rxdb/plugins/cleanup";
import { RxDBDevModePlugin } from "rxdb/plugins/dev-mode";
import { wrappedKeyEncryptionCryptoJsStorage } from "rxdb/plugins/encryption-crypto-js";
import { RxDBLeaderElectionPlugin } from "rxdb/plugins/leader-election";
import { RxDBMigrationSchemaPlugin } from "rxdb/plugins/migration-schema";
import { RxDBStatePlugin } from "rxdb/plugins/state";
import { getRxStorageDexie } from "rxdb/plugins/storage-dexie";
import { wrappedValidateZSchemaStorage } from "rxdb/plugins/validate-z-schema";

import type { ActivityCollection, ConfigurationCollection, QuestionCollection } from "./schemas";

addRxPlugin(RxDBLeaderElectionPlugin);
addRxPlugin(RxDBMigrationSchemaPlugin);
addRxPlugin(RxDBStatePlugin);
addRxPlugin(RxDBCleanupPlugin);

import.meta.env.DEV && addRxPlugin(RxDBDevModePlugin);

export type DatabaseCollections = {
  configuration?: ConfigurationCollection;
  questions?: QuestionCollection;
  activities?: ActivityCollection;
};

export type Database = RxDatabase<DatabaseCollections>;

export type DatabaseCollectionName = keyof DatabaseCollections;

let dbInstance: RxDatabase<DatabaseCollections> | null = null;

let initializationPromise: Promise<RxDatabase<DatabaseCollections>> | null = null;

const initializeDatabase = async (): Promise<RxDatabase<DatabaseCollections>> => {
  if (initializationPromise) {
    return initializationPromise;
  }

  if (dbInstance) {
    return dbInstance;
  }

  const password = "123456789$";

  const encryptedStorage = wrappedKeyEncryptionCryptoJsStorage({
    storage: getRxStorageDexie(),
  });

  const validatedStorage = wrappedValidateZSchemaStorage({
    storage: encryptedStorage,
  });

  initializationPromise = createRxDatabase<DatabaseCollections>({
    name: window.location.hostname ? window.location.hostname : "elitehq",
    storage: import.meta.env.DEV ? validatedStorage : encryptedStorage,
    eventReduce: true,
    multiInstance: false,
    password,
    cleanupPolicy: {
      minimumDeletedTime: 1000 * 60 * 60 * 24 * 7, // one week,
      minimumCollectionAge: 1000 * 60, // 60 seconds
      runEach: 1000 * 60 * 5, // 5 minutes
      awaitReplicationsInSync: true,
      waitForLeadership: true,
    },
  }).then((db) => {
    dbInstance = db;
    return db;
  });

  return initializationPromise;
};

const getDatabase = async (): Promise<RxDatabase<DatabaseCollections>> => {
  if (dbInstance === null) {
    dbInstance = await initializeDatabase();
  }
  return dbInstance;
};

export { getDatabase, initializeDatabase };
