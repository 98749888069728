import { ScrollShadow, useDisclosure } from "@heroui/react";
import type { FC, PropsWithChildren } from "react";

import { QuestionStatus } from "../components/global/QuestionStatus";
import { BottomTabs } from "./components/bottom-tabs";
import { Drawer } from "./components/drawer";
import { Navbar } from "./components/navbar";

const AdvancedLayout: FC<PropsWithChildren> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div className="flex h-full w-full flex-col">
      <Navbar sidebarIsOpen={isOpen} sidebarOnOpen={onOpen} sidebarOnClose={onClose} />
      <Drawer isOpen={isOpen} onClose={onClose} />
      <ScrollShadow className="h-full w-full">
        <div className=" max-w-screen-lg mx-auto p-4"> {children}</div>
      </ScrollShadow>
      <QuestionStatus />
      <BottomTabs />
    </div>
  );
};

export { AdvancedLayout };
