/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AppRouteImport } from './routes/_app.route'
import { Route as AppTrueFalseImport } from './routes/_app.true-false'
import { Route as AppProfileImport } from './routes/_app.profile'
import { Route as AppExamsImport } from './routes/_app.exams'
import { Route as AppSettingsRouteImport } from './routes/_app.settings.route'
import { Route as AppQuizzesRouteImport } from './routes/_app.quizzes.route'
import { Route as AppActivityActivityIdRouteImport } from './routes/_app.activity.$activityId.route'

// Create Virtual Routes

const StatusLazyImport = createFileRoute('/status')()
const LogoutLazyImport = createFileRoute('/logout')()
const InstallLazyImport = createFileRoute('/install')()
const LoginIndexLazyImport = createFileRoute('/login/')()
const AppIndexLazyImport = createFileRoute('/_app/')()
const AppSettingsIndexLazyImport = createFileRoute('/_app/settings/')()
const AppSettingsAboutLazyImport = createFileRoute('/_app/settings/about')()
const AppActivityActivityIdIndexLazyImport = createFileRoute(
  '/_app/activity/$activityId/',
)()
const AppActivityActivityIdResultsLazyImport = createFileRoute(
  '/_app/activity/$activityId/results',
)()

// Create/Update Routes

const StatusLazyRoute = StatusLazyImport.update({
  id: '/status',
  path: '/status',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/status.lazy').then((d) => d.Route))

const LogoutLazyRoute = LogoutLazyImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/logout.lazy').then((d) => d.Route))

const InstallLazyRoute = InstallLazyImport.update({
  id: '/install',
  path: '/install',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/install.lazy').then((d) => d.Route))

const AppRouteRoute = AppRouteImport.update({
  id: '/_app',
  getParentRoute: () => rootRoute,
} as any)

const LoginIndexLazyRoute = LoginIndexLazyImport.update({
  id: '/login/',
  path: '/login/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/login.index.lazy').then((d) => d.Route))

const AppIndexLazyRoute = AppIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AppRouteRoute,
} as any).lazy(() => import('./routes/_app.index.lazy').then((d) => d.Route))

const AppTrueFalseRoute = AppTrueFalseImport.update({
  id: '/true-false',
  path: '/true-false',
  getParentRoute: () => AppRouteRoute,
} as any).lazy(() =>
  import('./routes/_app.true-false.lazy').then((d) => d.Route),
)

const AppProfileRoute = AppProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => AppRouteRoute,
} as any).lazy(() => import('./routes/_app.profile.lazy').then((d) => d.Route))

const AppExamsRoute = AppExamsImport.update({
  id: '/exams',
  path: '/exams',
  getParentRoute: () => AppRouteRoute,
} as any).lazy(() => import('./routes/_app.exams.lazy').then((d) => d.Route))

const AppSettingsRouteRoute = AppSettingsRouteImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => AppRouteRoute,
} as any)

const AppQuizzesRouteRoute = AppQuizzesRouteImport.update({
  id: '/quizzes',
  path: '/quizzes',
  getParentRoute: () => AppRouteRoute,
} as any).lazy(() => import('./routes/_app.quizzes.lazy').then((d) => d.Route))

const AppSettingsIndexLazyRoute = AppSettingsIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AppSettingsRouteRoute,
} as any).lazy(() =>
  import('./routes/_app.settings.index.lazy').then((d) => d.Route),
)

const AppSettingsAboutLazyRoute = AppSettingsAboutLazyImport.update({
  id: '/about',
  path: '/about',
  getParentRoute: () => AppSettingsRouteRoute,
} as any).lazy(() =>
  import('./routes/_app.settings.about.lazy').then((d) => d.Route),
)

const AppActivityActivityIdRouteRoute = AppActivityActivityIdRouteImport.update(
  {
    id: '/activity/$activityId',
    path: '/activity/$activityId',
    getParentRoute: () => AppRouteRoute,
  } as any,
)

const AppActivityActivityIdIndexLazyRoute =
  AppActivityActivityIdIndexLazyImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AppActivityActivityIdRouteRoute,
  } as any).lazy(() =>
    import('./routes/_app.activity.$activityId.index.lazy').then(
      (d) => d.Route,
    ),
  )

const AppActivityActivityIdResultsLazyRoute =
  AppActivityActivityIdResultsLazyImport.update({
    id: '/results',
    path: '/results',
    getParentRoute: () => AppActivityActivityIdRouteRoute,
  } as any).lazy(() =>
    import('./routes/_app.activity.$activityId.results.lazy').then(
      (d) => d.Route,
    ),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_app': {
      id: '/_app'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AppRouteImport
      parentRoute: typeof rootRoute
    }
    '/install': {
      id: '/install'
      path: '/install'
      fullPath: '/install'
      preLoaderRoute: typeof InstallLazyImport
      parentRoute: typeof rootRoute
    }
    '/logout': {
      id: '/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof LogoutLazyImport
      parentRoute: typeof rootRoute
    }
    '/status': {
      id: '/status'
      path: '/status'
      fullPath: '/status'
      preLoaderRoute: typeof StatusLazyImport
      parentRoute: typeof rootRoute
    }
    '/_app/quizzes': {
      id: '/_app/quizzes'
      path: '/quizzes'
      fullPath: '/quizzes'
      preLoaderRoute: typeof AppQuizzesRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/_app/settings': {
      id: '/_app/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof AppSettingsRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/_app/exams': {
      id: '/_app/exams'
      path: '/exams'
      fullPath: '/exams'
      preLoaderRoute: typeof AppExamsImport
      parentRoute: typeof AppRouteImport
    }
    '/_app/profile': {
      id: '/_app/profile'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof AppProfileImport
      parentRoute: typeof AppRouteImport
    }
    '/_app/true-false': {
      id: '/_app/true-false'
      path: '/true-false'
      fullPath: '/true-false'
      preLoaderRoute: typeof AppTrueFalseImport
      parentRoute: typeof AppRouteImport
    }
    '/_app/': {
      id: '/_app/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AppIndexLazyImport
      parentRoute: typeof AppRouteImport
    }
    '/login/': {
      id: '/login/'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/_app/activity/$activityId': {
      id: '/_app/activity/$activityId'
      path: '/activity/$activityId'
      fullPath: '/activity/$activityId'
      preLoaderRoute: typeof AppActivityActivityIdRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/_app/settings/about': {
      id: '/_app/settings/about'
      path: '/about'
      fullPath: '/settings/about'
      preLoaderRoute: typeof AppSettingsAboutLazyImport
      parentRoute: typeof AppSettingsRouteImport
    }
    '/_app/settings/': {
      id: '/_app/settings/'
      path: '/'
      fullPath: '/settings/'
      preLoaderRoute: typeof AppSettingsIndexLazyImport
      parentRoute: typeof AppSettingsRouteImport
    }
    '/_app/activity/$activityId/results': {
      id: '/_app/activity/$activityId/results'
      path: '/results'
      fullPath: '/activity/$activityId/results'
      preLoaderRoute: typeof AppActivityActivityIdResultsLazyImport
      parentRoute: typeof AppActivityActivityIdRouteImport
    }
    '/_app/activity/$activityId/': {
      id: '/_app/activity/$activityId/'
      path: '/'
      fullPath: '/activity/$activityId/'
      preLoaderRoute: typeof AppActivityActivityIdIndexLazyImport
      parentRoute: typeof AppActivityActivityIdRouteImport
    }
  }
}

// Create and export the route tree

interface AppSettingsRouteRouteChildren {
  AppSettingsAboutLazyRoute: typeof AppSettingsAboutLazyRoute
  AppSettingsIndexLazyRoute: typeof AppSettingsIndexLazyRoute
}

const AppSettingsRouteRouteChildren: AppSettingsRouteRouteChildren = {
  AppSettingsAboutLazyRoute: AppSettingsAboutLazyRoute,
  AppSettingsIndexLazyRoute: AppSettingsIndexLazyRoute,
}

const AppSettingsRouteRouteWithChildren =
  AppSettingsRouteRoute._addFileChildren(AppSettingsRouteRouteChildren)

interface AppActivityActivityIdRouteRouteChildren {
  AppActivityActivityIdResultsLazyRoute: typeof AppActivityActivityIdResultsLazyRoute
  AppActivityActivityIdIndexLazyRoute: typeof AppActivityActivityIdIndexLazyRoute
}

const AppActivityActivityIdRouteRouteChildren: AppActivityActivityIdRouteRouteChildren =
  {
    AppActivityActivityIdResultsLazyRoute:
      AppActivityActivityIdResultsLazyRoute,
    AppActivityActivityIdIndexLazyRoute: AppActivityActivityIdIndexLazyRoute,
  }

const AppActivityActivityIdRouteRouteWithChildren =
  AppActivityActivityIdRouteRoute._addFileChildren(
    AppActivityActivityIdRouteRouteChildren,
  )

interface AppRouteRouteChildren {
  AppQuizzesRouteRoute: typeof AppQuizzesRouteRoute
  AppSettingsRouteRoute: typeof AppSettingsRouteRouteWithChildren
  AppExamsRoute: typeof AppExamsRoute
  AppProfileRoute: typeof AppProfileRoute
  AppTrueFalseRoute: typeof AppTrueFalseRoute
  AppIndexLazyRoute: typeof AppIndexLazyRoute
  AppActivityActivityIdRouteRoute: typeof AppActivityActivityIdRouteRouteWithChildren
}

const AppRouteRouteChildren: AppRouteRouteChildren = {
  AppQuizzesRouteRoute: AppQuizzesRouteRoute,
  AppSettingsRouteRoute: AppSettingsRouteRouteWithChildren,
  AppExamsRoute: AppExamsRoute,
  AppProfileRoute: AppProfileRoute,
  AppTrueFalseRoute: AppTrueFalseRoute,
  AppIndexLazyRoute: AppIndexLazyRoute,
  AppActivityActivityIdRouteRoute: AppActivityActivityIdRouteRouteWithChildren,
}

const AppRouteRouteWithChildren = AppRouteRoute._addFileChildren(
  AppRouteRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof AppRouteRouteWithChildren
  '/install': typeof InstallLazyRoute
  '/logout': typeof LogoutLazyRoute
  '/status': typeof StatusLazyRoute
  '/quizzes': typeof AppQuizzesRouteRoute
  '/settings': typeof AppSettingsRouteRouteWithChildren
  '/exams': typeof AppExamsRoute
  '/profile': typeof AppProfileRoute
  '/true-false': typeof AppTrueFalseRoute
  '/': typeof AppIndexLazyRoute
  '/login': typeof LoginIndexLazyRoute
  '/activity/$activityId': typeof AppActivityActivityIdRouteRouteWithChildren
  '/settings/about': typeof AppSettingsAboutLazyRoute
  '/settings/': typeof AppSettingsIndexLazyRoute
  '/activity/$activityId/results': typeof AppActivityActivityIdResultsLazyRoute
  '/activity/$activityId/': typeof AppActivityActivityIdIndexLazyRoute
}

export interface FileRoutesByTo {
  '/install': typeof InstallLazyRoute
  '/logout': typeof LogoutLazyRoute
  '/status': typeof StatusLazyRoute
  '/quizzes': typeof AppQuizzesRouteRoute
  '/exams': typeof AppExamsRoute
  '/profile': typeof AppProfileRoute
  '/true-false': typeof AppTrueFalseRoute
  '/': typeof AppIndexLazyRoute
  '/login': typeof LoginIndexLazyRoute
  '/settings/about': typeof AppSettingsAboutLazyRoute
  '/settings': typeof AppSettingsIndexLazyRoute
  '/activity/$activityId/results': typeof AppActivityActivityIdResultsLazyRoute
  '/activity/$activityId': typeof AppActivityActivityIdIndexLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_app': typeof AppRouteRouteWithChildren
  '/install': typeof InstallLazyRoute
  '/logout': typeof LogoutLazyRoute
  '/status': typeof StatusLazyRoute
  '/_app/quizzes': typeof AppQuizzesRouteRoute
  '/_app/settings': typeof AppSettingsRouteRouteWithChildren
  '/_app/exams': typeof AppExamsRoute
  '/_app/profile': typeof AppProfileRoute
  '/_app/true-false': typeof AppTrueFalseRoute
  '/_app/': typeof AppIndexLazyRoute
  '/login/': typeof LoginIndexLazyRoute
  '/_app/activity/$activityId': typeof AppActivityActivityIdRouteRouteWithChildren
  '/_app/settings/about': typeof AppSettingsAboutLazyRoute
  '/_app/settings/': typeof AppSettingsIndexLazyRoute
  '/_app/activity/$activityId/results': typeof AppActivityActivityIdResultsLazyRoute
  '/_app/activity/$activityId/': typeof AppActivityActivityIdIndexLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/install'
    | '/logout'
    | '/status'
    | '/quizzes'
    | '/settings'
    | '/exams'
    | '/profile'
    | '/true-false'
    | '/'
    | '/login'
    | '/activity/$activityId'
    | '/settings/about'
    | '/settings/'
    | '/activity/$activityId/results'
    | '/activity/$activityId/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/install'
    | '/logout'
    | '/status'
    | '/quizzes'
    | '/exams'
    | '/profile'
    | '/true-false'
    | '/'
    | '/login'
    | '/settings/about'
    | '/settings'
    | '/activity/$activityId/results'
    | '/activity/$activityId'
  id:
    | '__root__'
    | '/_app'
    | '/install'
    | '/logout'
    | '/status'
    | '/_app/quizzes'
    | '/_app/settings'
    | '/_app/exams'
    | '/_app/profile'
    | '/_app/true-false'
    | '/_app/'
    | '/login/'
    | '/_app/activity/$activityId'
    | '/_app/settings/about'
    | '/_app/settings/'
    | '/_app/activity/$activityId/results'
    | '/_app/activity/$activityId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AppRouteRoute: typeof AppRouteRouteWithChildren
  InstallLazyRoute: typeof InstallLazyRoute
  LogoutLazyRoute: typeof LogoutLazyRoute
  StatusLazyRoute: typeof StatusLazyRoute
  LoginIndexLazyRoute: typeof LoginIndexLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  AppRouteRoute: AppRouteRouteWithChildren,
  InstallLazyRoute: InstallLazyRoute,
  LogoutLazyRoute: LogoutLazyRoute,
  StatusLazyRoute: StatusLazyRoute,
  LoginIndexLazyRoute: LoginIndexLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_app",
        "/install",
        "/logout",
        "/status",
        "/login/"
      ]
    },
    "/_app": {
      "filePath": "_app.route.tsx",
      "children": [
        "/_app/quizzes",
        "/_app/settings",
        "/_app/exams",
        "/_app/profile",
        "/_app/true-false",
        "/_app/",
        "/_app/activity/$activityId"
      ]
    },
    "/install": {
      "filePath": "install.lazy.tsx"
    },
    "/logout": {
      "filePath": "logout.lazy.tsx"
    },
    "/status": {
      "filePath": "status.lazy.tsx"
    },
    "/_app/quizzes": {
      "filePath": "_app.quizzes.route.tsx",
      "parent": "/_app"
    },
    "/_app/settings": {
      "filePath": "_app.settings.route.tsx",
      "parent": "/_app",
      "children": [
        "/_app/settings/about",
        "/_app/settings/"
      ]
    },
    "/_app/exams": {
      "filePath": "_app.exams.tsx",
      "parent": "/_app"
    },
    "/_app/profile": {
      "filePath": "_app.profile.tsx",
      "parent": "/_app"
    },
    "/_app/true-false": {
      "filePath": "_app.true-false.tsx",
      "parent": "/_app"
    },
    "/_app/": {
      "filePath": "_app.index.lazy.tsx",
      "parent": "/_app"
    },
    "/login/": {
      "filePath": "login.index.lazy.tsx"
    },
    "/_app/activity/$activityId": {
      "filePath": "_app.activity.$activityId.route.tsx",
      "parent": "/_app",
      "children": [
        "/_app/activity/$activityId/results",
        "/_app/activity/$activityId/"
      ]
    },
    "/_app/settings/about": {
      "filePath": "_app.settings.about.lazy.tsx",
      "parent": "/_app/settings"
    },
    "/_app/settings/": {
      "filePath": "_app.settings.index.lazy.tsx",
      "parent": "/_app/settings"
    },
    "/_app/activity/$activityId/results": {
      "filePath": "_app.activity.$activityId.results.lazy.tsx",
      "parent": "/_app/activity/$activityId"
    },
    "/_app/activity/$activityId/": {
      "filePath": "_app.activity.$activityId.index.lazy.tsx",
      "parent": "/_app/activity/$activityId"
    }
  }
}
ROUTE_MANIFEST_END */
