import { useContext } from "react";
import { SupabaseContext } from "../../providers/supabase";

const useAuth = () => {
  const { auth } = useContext(SupabaseContext);

  if (!auth) {
    throw Error("useAuth must be used within SupabaseProvider");
  }

  return auth;
};

export { useAuth };
