import { Link } from "@heroui/react";
import type { FC } from "react";

const NotFoundMessage: FC = () => {
  return (
    <div>
      <p>Not found!</p>
      <Link href="/">Go home</Link>
    </div>
  );
};

export { NotFoundMessage };
