const defaultConfiguration = {
  labels: {},
  default: {
    exam: {
      items: [],
      enabled: false,
    },
    quiz: {
      series: {
        items: ["202"],
        enabled: true,
      },
      enabled: true,
      procedures: {
        items: [],
        enabled: false,
      },
    },
    trueFalse: {
      series: {
        items: [],
        enabled: false,
      },
      enabled: false,
      procedures: {
        items: [],
        enabled: false,
      },
    },
  },
  sergeant: {
    exam: {
      items: [],
      enabled: false,
    },
    quiz: {
      series: {
        items: [],
        enabled: false,
      },
      enabled: false,
      procedures: {
        items: [],
        enabled: false,
      },
    },
    trueFalse: {
      series: {
        items: [],
        enabled: false,
      },
      enabled: false,
      procedures: {
        items: [],
        enabled: false,
      },
    },
  },
  lieutenant: {
    exam: {
      items: [],
      enabled: false,
    },
    quiz: {
      series: {
        items: [],
        enabled: false,
      },
      enabled: false,
      procedures: {
        items: [],
        enabled: false,
      },
    },
    trueFalse: {
      series: {
        items: [],
        enabled: false,
      },
      enabled: false,
      procedures: {
        items: [],
        enabled: false,
      },
    },
  },
  captain: {
    exam: {
      items: [],
      enabled: false,
    },
    quiz: {
      series: {
        items: [],
        enabled: false,
      },
      enabled: false,
      procedures: {
        items: [],
        enabled: false,
      },
    },
    trueFalse: {
      series: {
        items: [],
        enabled: false,
      },
      enabled: false,
      procedures: {
        items: [],
        enabled: false,
      },
    },
  },
};

export { defaultConfiguration };
