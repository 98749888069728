import { useEffect, useState } from "react";

import { useAuth } from "@est/supabase/hooks";

import { defaultConfiguration } from "../../constants";
import { useCollection } from "./useCollection";

type LabelConfig = Record<string, string>;

type SubConfiguration = {
  items: string[];
  enabled: boolean;
};

type ActivityConfiguration = {
  series: SubConfiguration;
  enabled: boolean;
  procedures: SubConfiguration;
};

type ActivityRankConfig = {
  exam: SubConfiguration;
  quiz: ActivityConfiguration;
  trueFalse: ActivityConfiguration;
};

const useConfiguration = () => {
  const configuration = useCollection("configuration");

  if (!configuration) {
    throw new Error("Configuration collection not initialized");
  }

  const { claims } = useAuth();
  const [config, setConfig] = useState<ActivityRankConfig>(defaultConfiguration.default);
  const [labels, setLabels] = useState<LabelConfig>({});

  useEffect(() => {
    (async () => {
      const labelConfigDoc = await configuration.findOne("labels").exec();
      const subscribe = labelConfigDoc?.$.subscribe((doc) => {
        if (doc) {
          setLabels(doc.value as LabelConfig);
        }
      });
      return subscribe?.unsubscribe();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const rankConfigDoc = await configuration.findOne(claims?.rank).exec();
      const subscribe = rankConfigDoc?.$.subscribe((doc) => {
        if (doc) {
          setConfig(doc.value as ActivityRankConfig);
        }
      });
      return subscribe?.unsubscribe();
    })();
  }, [claims?.rank]);

  return {
    config,
    labels,
  };
};

export { useConfiguration };
