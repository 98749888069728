import { useEffect, useState } from "react";

import { useDataState } from "../../providers/data.provider";

export type ApplicationState = {
  lastFrequent?: number;
  lastInfrequent?: number;
  lastMoodleUserId?: number;
  lastUserId?: string;
};

type ApplicationActions = {
  updateLogin: (id: string, moodleId: number) => void;
  updateTimestamp: (name: "lastFrequent" | "lastInfrequent", timestamp: number) => void;
};

const useApplication = (): ApplicationState & ApplicationActions => {
  const [state, setState] = useState<ApplicationState>();
  const application = useDataState("application");

  useEffect(() => {
    if (application) {
      setState(application.get());
      const appSub = application.$.subscribe((data: ApplicationState) => {
        setState(data);
      });
      return () => appSub.unsubscribe();
    }
  }, [application]);

  const updateLogin: ApplicationActions["updateLogin"] = (id: string, moodleId: number) => {
    application?.set("lastUserId", () => id);
    application?.set("lastMoodleUserId", () => moodleId);
  };

  const updateTimestamp: ApplicationActions["updateTimestamp"] = (name, timestamp) => {
    application.set(name, () => timestamp);
  };

  return {
    ...state,
    updateTimestamp,
    updateLogin,
  };
};

export { useApplication };
