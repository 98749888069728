import { type ReplicationPullHandlerResult, lastOfArray } from "rxdb";
import { replicateRxCollection } from "rxdb/plugins/replication";

import { supabaseClient } from "../../supabase/supabase";
import { rowToCheckpoint, rowToRxDoc } from "../../utilities/rxdbHelpers";
import type { QuestionCollection, QuestionDocType } from "../schemas/question.types";
import type { SupabaseReplicationCheckpoint } from "./";

const initializeQuestionsReplication = async (collection: QuestionCollection) => {
  return replicateRxCollection({
    collection,
    replicationIdentifier: "question-replication",
    live: true,
    autoStart: true,
    pull: {
      async handler(
        lastCheckpoint: SupabaseReplicationCheckpoint | undefined,
        batchSize: number,
      ): Promise<ReplicationPullHandlerResult<QuestionDocType, SupabaseReplicationCheckpoint>> {
        const { data, error } = await supabaseClient.rpc("pull_questions", {
          batch_size: batchSize ? batchSize : null,
          last_id: lastCheckpoint?.id,
          last_pulled_at: lastCheckpoint?.modified,
        });

        if (error) throw error;

        if (data.length === 0) {
          return {
            checkpoint: lastCheckpoint ?? null,
            documents: [],
          };
        }

        return {
          checkpoint: rowToCheckpoint(lastOfArray(data) || {}),
          documents: data.map((row: any) => rowToRxDoc<QuestionDocType>(row)),
        };
      },
      batchSize: 200,
    },
  });
};

export { initializeQuestionsReplication };
