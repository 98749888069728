import type { FC, SVGProps } from "react";
import Logo from "../../assets/favicon.svg?react";

export type IconSvgProps = SVGProps<SVGSVGElement> & {
  size?: number;
};

const EliteHqIcon: FC<IconSvgProps> = (props) => <Logo {...props} />;

export default EliteHqIcon;
