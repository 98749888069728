import { useRegisterSW } from "virtual:pwa-register/react";
import { Button } from "@heroui/react";
import { Cron } from "croner";
import { format } from "date-fns";
import { useEffect, useState } from "react";

import { useAnalytics } from "../../hooks";
import { log } from "../../utilities";

export const ServiceWorker = () => {
  const [swRegistration, setSwRegistration] = useState<ServiceWorkerRegistration>();
  const [swUrl, setSwUrl] = useState<string>();
  const { trackEvent } = useAnalytics();

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(sw, serviceWorkerRegistration) {
      setSwUrl(sw);
      setSwRegistration(serviceWorkerRegistration);

      log.debug("SW registered: ", sw);
    },
    onRegisterError(error) {
      log.error("SW registration error", error);
    },
  });

  useEffect(() => {
    if (swRegistration) {
      const job = new Cron("*/10 * * * *", async () => {
        if ("connection" in navigator && !navigator.onLine) {
          log.debug("Application offline, not checking for updates.");
          return;
        }

        log.debug(`Checking for application updates (${format(Date.now(), "MM/dd/yyyy HH:mm:ss")})`);

        try {
          if (swUrl) {
            const resp = await fetch(swUrl, {
              method: "HEAD",
              cache: "no-store",
              headers: {
                cache: "no-store",
                "cache-control": "no-cache",
              },
            });

            if (resp?.status === 200) {
              await swRegistration.update();
            }
          }
        } catch (e) {
          // just ignore
        }

        // void swRegistration.update();
      });

      return () => {
        job.stop(); // Cleanup on unmount
      };
    }
  }, [swRegistration]);

  const handleUpdate = () => {
    trackEvent("updated");
    void updateServiceWorker(true);
    setNeedRefresh(false);
  };

  if (!needRefresh) {
    return <></>;
  }

  return (
    <div className="pointer-events-none fixed inset-x-0 bottom-0 content">
      <div className="pointer-events-auto flex w-full items-center justify-between gap-x-20 border border-divider bg-primary px-4 py-2 shadow-small">
        <p className="text-small font-normal text-primary-foreground">An update is available.</p>
        <div className="flex items-center gap-2">
          <Button className="bg-primary-foreground font-medium text-primary" radius="lg" onPress={handleUpdate}>
            Reload
          </Button>
        </div>
      </div>
    </div>
  );
};
