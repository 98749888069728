import { RouterProvider, createRouter } from "@tanstack/react-router";
import type { FC } from "react";

import { useAuth } from "@est/supabase/hooks";
import type { User } from "@supabase/supabase-js";

import { ErrorMessage } from "../components/ErrorMessage";
import { NotFoundMessage } from "../components/NotFoundMessage";
import type { ActivityCollection } from "../db/schemas";
import { useCollection } from "../hooks/data/useCollection";

import { routeTree } from "../routeTree.gen";

type RouteContext = {
  getTitle?: () => string | Promise<string>;
  user: User | null;
  activities: ActivityCollection | null;
};

export const router = createRouter({
  routeTree,
  context: {
    user: null,
    activities: null,
  },
  defaultErrorComponent: ErrorMessage,
  defaultNotFoundComponent: NotFoundMessage,
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
  interface StaticDataRouteOption {
    title?: string;
    navigation?: { label: string; href: string; icon: any }[];
  }
}

const RouteProvider: FC = () => {
  const { user } = useAuth();
  const activities = useCollection("activities");

  return <RouterProvider router={router} context={{ user, activities }} />;
};

export type { RouteContext };
export { RouteProvider };
